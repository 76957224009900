import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page" className="pageFrontpage">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/">UK</a>
                      </li>
                      <li>
                        <a href="/es/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/">PT</a>
                      </li>
                      <li>
                        <a href="/it/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper">
              <div className="home-slider">
                <div className="areaInner">
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".flexslider_edit_disable{width:100%;min-height:20px;background:#999999;padding:10px;text-align:center;color:#fff}\n                        .flexslider_edit_disable.error{color:#cf0000}\n                        a:focus{outline:none!important;}\n                     "
                    }}
                  />
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".flexslider-container4177 {margin:0 auto;position:relative;\t}.flexslider-container4177 img {max-height:450px;}.flexslider-container4177 li {margin-right:0px} .flexslider-container4177 {padding:0px;padding-right:0px;} .flexslider-container4177 img {max-width:100%;}\n                        .flexslider-container4177 {margin:0 auto 0px auto}\n                        .flexslider-container4177 h1 {margin:0;font-size:35px;font-weight:normal;text-align:left;}\n                     "
                    }}
                  />
                  <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html:
                        ' $(window).on(\'load\', function() {$(".flexslider-container4177").flexslider({animation:"slide",direction:"horizontal",reverse:false,animationLoop:true,startAt:0,slideshow :true,slideshowSpeed :7000,animationSpeed :600,initDelay :0,randomize :false,pauseOnAction :false,pauseOnHover :true,smoothHeight :false,useCSS :true,touch :true,video :true,controlNav :true,directionNav :false,keyboard :true,multipleKeyboard :true,mousewheel :false,pausePlay :false,itemMargin:0,minItems:1,maxItems :1,itemWidth: 1680,\nnamespace: "flex-",\nstart: function(){\n$(".flexslider-img-preloader").css("background-image","none");\n$(".flexslider-img-content").css("opacity","1");\n$(".flexslider-img-content").css("visibility","visible");\n}\n});\n});\n'
                    }}
                  />
                  <div className="external-flexslider-container4177 flexslider-img-preloader">
                    <div className="default-flexslider flexslider-container4177 flexslider-img-content">
                      <ul className="slides4177 default-flex-slides slides">
                        <li>
                          <a href="/it/prodotti/hydro-infusion/">
                            {" "}
                            <img src="/assets/images/Banner-Hidroinfusion-Homepage.png" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                <br />
                                ASSORBIMENTO RAPIDO
                                <br /> &amp; IMMEDIATA SENSAZIONE <br />
                                DI FRESCHEZZA
                              </h3>
                              <p
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                CON LA CREMA SOLARE GEL <br /> PIZ BUIN HYDRO
                                INFUSION<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/it/prodotti/hydro-infusion/"
                              >
                                Leggi di piú
                              </a>
                            </div>
                          </div>
                        </li>
                        {/* <li>
                          <a href="/it/prodotti/active-protect/">
                            {" "}
                            <img src="/assets/images/2019-banner-travel-size-home.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                <br />
                                IDRATAZIONE INTENSA
                                <br /> A LUNGA DURATA <br />
                              </h3>
                              <p
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                PIZ BUIN ACTIVE &amp; PROTECT<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/it/prodotti/active-protect/"
                              >
                                Leggi di piú
                              </a>
                            </div>
                          </div>
                        </li> */}
                        {/* <li>
                          <a href="/it/prodotti/instantglow/">
                            {" "}
                            <img src="/assets/images/2019-instant-glow-home.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "!important"
                                }}
                              >
                                <br />
                                PER UN'ABBRONZATURA PIÙ RAPIDA* E BELLA, IN
                                TUTTA SICUREZZA.
                              </h3>
                              <p
                                style={{
                                  color: "!important"
                                }}
                              >
                                PIZ BUIN INSTANT GLOW<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                href="/it/prodotti/instantglow/"
                              >
                                Leggi di piu´
                              </a>
                              <p
                                style={{
                                  "font-size": "12px",
                                  "margin-top": "12px",
                                  color: "#000 !important ",
                                  "background-color": "#fff"
                                }}
                              >
                                *Contiene una tecnologia che intensifica la
                                naturale abbronzatura.<sup>®</sup>
                              </p>
                            </div>
                          </div>
                        </li> */}
                        <li>
                          <a href="/it/prodotti/tan_protect/">
                            {" "}
                            <img src="/assets/images/slider-2020-tan-protect.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                Aumenta la naturale abbronzatura
                              </h3>
                              <p
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                PIZ BUIN TAN &amp; PROTECT<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                href="/it/prodotti/tan_protect/"
                              >
                                Leggi di piu´
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="/it/prodotti/mountain/">
                            {" "}
                            <img src="/assets/images/Banner-Mountain-Homepage.png" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "!important"
                                }}
                              >
                                Protezione speciale per le condizioni di sole,
                                freddo e vento ad alta quota
                              </h3>
                              <p
                                style={{
                                  color: "!important"
                                }}
                              >
                                PIZ BUIN<sup>®</sup> MOUNTAIN
                              </p>
                              <a className="btn" href="/it/prodotti/mountain/">
                                Leggi di piu´
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    style={{
                      clear: "both"
                    }}
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent clearfix">
                <div className="section">
                  <figure>
                    <a href="/it/prodotti/gallery/">
                      <img src="/assets/images/Products taco.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/it/prodotti/gallery/">
                        Un messaggio importante ai nostri Sun Lovers
                      </a>
                    </h3>
                    <h4>
                      <a href="/it/prodotti/gallery/">Scopri di piu’</a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
                <div className="section">
                  <figure>
                    <a href="/it/know_the_sun/">
                      <img src="/assets/images/CallToAction_640x370px_KnowTheSun.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/it/know_the_sun/">CONOSCI IL SOLE</a>
                    </h3>
                    <h4>
                      <a href="/it/know_the_sun/">
                        <br />
                        La nostra guida per goderti il sole in tutta sicurezza
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
                <div className="section">
                  <figure>
                    <a href="/it/our-heritage/">
                      <img src="/assets/images/mobile_landing_OurHeritage_x2.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/it/our-heritage/">IL NOSTRO HERITAGE</a>
                    </h3>
                    <h4>
                      <a href="/it/our-heritage/">
                        70 anni di esperienza nelle protezioni solari
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.min.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        
      </div>
    );
  }
}

export default Page;
